import {debounce} from "Services/Debounce";
import Constants from "../Constants";
import {requestExportProducts} from "../Services/RequestService";

const requestFailed = data => ({type: Constants.FETCH_ITEMS_FAILED, data});

export const requestItem = () => ({type: Constants.FETCH_ITEMS_REQUESTED, data: {loading: true}});

const requestSucceeded = data => ({type: Constants.FETCH_ITEMS_SUCCEEDED, data});

const requestNextPageSucceeded = data => ({type: Constants.NEXT_PAGE, data});

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const {sort} = data;
    const {requestTable} = service;

    requestTable(data).then(
        res => {
            dispatch(requestSucceeded({...res, sort: {...(sort || {})}}));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const {requestTable} = service;

    requestTable(data).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

const filterList = [
    "supplier",
    "brand",
    "grade",
    "part_approval",
    "part_approval_legacy",
    "part_approval_custom",
    "material_number",
    "legacy_product_number",
    "material_type",
    "product_code",
    "publication_id",
    "publication_ids",
    "slug",
    "paintable",
    "sub_status",
    "keywords",
    "color",
    "approval",
    "color_code",
    "publication",
    "user",
    "country",
    "contact",
    "property",
    "method",
    "specimen",
    "condition",
    "process_property",
    "process_parameter",
    "project_market_segment",
    "project_segment",
    "project_sub_segment",
    "application_code",
    "market_segment",
    "segment",
    "sub_segment",
    "location",
    "plant",
    "sap_plant",
    "sap_line",
    "line",
    "datasheet_unit",
    "unit_value_max",
    "process_unit",
    "customer",
    "customer_code",
    "project_slug",
    "project_id",
    "molded",
    "boundable",
    "group_id",
    "has_invoice",
    "has_project",
]

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({type: Constants.CHANGE_FILTER, data: {key, value}});
    if (['range_type', 'range_property', 'range_unit', 'unit_value_min'].includes(key)) {
        return;
    }

    if (filterList.includes(key)) {
        fetchItems(service, dispatch)(request);
        return;
    }

    if (key === 'approval_name' && value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }

    if ((key === 'name' || key === 'group_id' || key === 'content' || key === 'approval_name') && !value.length) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const onChangeSavedFilter = (service, dispatch) => (value) => {
    const {requestSavedFilterData} = service;
    dispatch({type: Constants.SAVED_FILTER_DATA_REQUESTED, data: {value}});

    return requestSavedFilterData(`/product/product-finder/filters/data/${value}`);
};

export const reloadFilter = (service, dispatch) => (request) => {
    dispatch({type: Constants.RELOAD_FILTER, data: request});
    fetchItems(service, dispatch)(request);
};

export const resetFilter = (service, dispatch) => (request) => {
    dispatch({type: Constants.RESET_FILTER, data: {}});
    fetchItems(service, dispatch)(request);
};

export const resetFilterWithoutFetch = (service, dispatch) => () => {
    dispatch({type: Constants.RESET_FILTER, data: {}});
};

export const getUrlExport = (service, dispatch) => (data) => {
    dispatch({type: Constants.EXPORT_PRODUCTS, data});

    return requestExportProducts(data).then(
        res => {
            alert('Export Products has started. Check notifications to download.');
            dispatch({type: Constants.EXPORT_PRODUCTS_SUCCESS, data: res});
        },
        res => {
            alert(res.detail);
            dispatch({type: Constants.EXPORT_PRODUCTS_FAILED, data: res});
        },
    );
};
