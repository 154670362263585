import React from "react";
import {InputFilter} from "Templates/Table/Filters";
import _ from "lodash";
import {
    gradeStatus,
    industryOptions,
    isSapImported,
    projectBudget,
    projectConsistent,
    projectStatusActive,
    projectStatusArchived,
    stageGates
} from "Services/enum";
import {url} from "Services";
import {useSelector} from 'react-redux';
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import tabList from "./tabList";
import {withTagDefaultProps} from "Hoc/Template";

const GetProjectFilters = ({selectedFilter, value, onChange, totalAnalytics}) => {
    const unit = useSelector(state => state.weightUnitState);

    const placeholders = {
        slug: "Search",
        name: "Search",
        owner: "Search",
        sub_status: "Select",
        stage_gate: "Select",
        status: "Select",
        budget: "Select",
        industry: "Search",
        application_code: "Search",
        sub_segment: "Search",
        application: "Search",
        product: "Search",
        grades: "Search",
        grade_sub_status: "Select",
        material_numbers: "Search",
        legacy_material_numbers: "Search",
        product_codes: "Search",
        product_application_codes: "Search",
        plants: "Search",
        lines: "Search",
        customer: "Search",
        customer_code: "Search",
        customer_nickname: "Search",
        customer_duns_number: "Search",
        manufacturer: "Search",
        tier1: "Search",
        tier1_nickname: "Search",
        tier2: "Search",
        tier2_nickname: "Search",
        molder: "Search",
        molder_nickname: "Search",
        unit: "Search",
        sop: "Search",
        move_to_current: "Move to Current",
        eop: "Search",
        los_date: "Search",
        created_at: "Search",
        updated_at: "Search",
        sub_status_changed_at: "Search",
        manager: "Search",
        internal_team: "Search",
        platform: "Search",
        program: "Search",
        brand: "Search",
        nameplate: "Search",
        country: "Search",
        region: "Search",
        production_plant: "Search",
        city: "Search",
        volume: "Search",
        revenue: "Search",
        gross_margin: "Search",
        actual_sale_price: "Search",
        weight_value: "Search",
        unit_value: "Search",
        company_is_sap: "Search",
        grade_is_sap: "Search",
        note: "Search",
    };

    switch (selectedFilter) {
        case "slug":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/project-slug"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/project-list"
                    name="name"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/owner-list"
                    name="owner"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "product":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/product-list"
                    name="product"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "grades":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/grade-list"
                    name="grades"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "material_numbers":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/material-number-list"
                    name="material_numbers"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "legacy_material_numbers":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/legacy-material-number-list"
                    name="legacy_material_numbers"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "company_is_sap":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...isSapImported]}
                    placeholder={_.get(placeholders, selectedFilter)}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "grade_is_sap":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...isSapImported]}
                    placeholder={_.get(placeholders, selectedFilter)}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "grade_sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={gradeStatus}
                    placeholder={_.get(placeholders, selectedFilter)}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "product_codes":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="project/projects/product-code-list"
                    name="product_codes"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "product_application_codes":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/application-code-list"
                    name="product_application_codes"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "plants":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/plant-list"
                    name="plants"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "lines":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/line-list"
                    name="lines"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "internal_team":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/user-list"
                    name="internal_team"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "brand":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/brand-list"
                    name="brand"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "nameplate":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/nameplate-list"
                    name="nameplate"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={
                        +url.getActiveTab() === tabList.archive ? projectStatusArchived : [...projectStatusActive]
                    }
                    placeholder={_.get(placeholders, selectedFilter)}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "stage_gate":
            return (
                <InputFilter
                    type="multiSelect"
                    name={selectedFilter}
                    value={value}
                    options={stageGates}
                    placeholder={_.get(placeholders, selectedFilter)}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "status":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...projectConsistent]}
                    placeholder={_.get(placeholders, selectedFilter)}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "budget":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}], ...projectBudget]}
                    placeholder={_.get(placeholders, selectedFilter)}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "application_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/application-code-list"
                    name="application_code"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "sub_segment":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/sub-segment-list"
                    name="sub_segment"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "application":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/application-list"
                    name="application"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "manufacturer":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/manufacturer-forecast-list"
                    name="manufacturer"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "platform":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/platform-list"
                    name="platform"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "program":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/program-list"
                    name="program"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "city":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/city-list"
                    name="city"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "country":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/country-list"
                    name="country"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "region":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/region-list"
                    name="region"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "production_plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/production-plant-list"
                    name="production_plant"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "unit":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/unit-list"
                    name="unit"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "industry":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={industryOptions}
                    placeholder={_.get(placeholders, selectedFilter)}
                    name="industry"
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "move_to_current":
        case "sop":
        case "eop":
        case "los_date":
        case "created_at":
        case "updated_at":
        case "sub_status_changed_at":
            return (
                <InputFilter
                    type="date-range"
                    viewFormat="MMM DD, YYYY"
                    placeholder={_.get(placeholders, selectedFilter)}
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "customer":
        case "tier1":
        case "tier2":
        case "molder":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/customer-list"
                    name={selectedFilter}
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "customer_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/customer-code-list"
                    name={selectedFilter}
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "customer_duns_number":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/customer-duns-list"
                    name={selectedFilter}
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "customer_nickname":
        case "tier1_nickname":
        case "tier2_nickname":
        case "molder_nickname":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/customer-nickname-list"
                    name={selectedFilter}
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "replacement_business_project_id":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/project-slug"
                    name={selectedFilter}
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "replacement_business_project_name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/project-list"
                    name={selectedFilter}
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "manager":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, selectedFilter)}
                    url="/project/projects/user-list"
                    name={selectedFilter}
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );

        case "volume":
        case "revenue":
        case "gross_margin": {
            return (
                <InputFilter
                    disabled
                    type="text"
                    placeholder=""
                    value=""
                    onChange={() => {
                    }}
                />
            );
        }

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder="Type in your filter value"
                    value={value}
                    onChange={e => {
                        onChange(e);
                    }}
                />
            );
    }
};

export default compose(
    withServiceConsumer,
    withTagDefaultProps()
)(GetProjectFilters);