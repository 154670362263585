const tableColumns = {
    status: "Added to ComOpps Forecast",
    budget: "Added to Budget & Sales Forecast",
    weight_value: "Part Weight (lbs)",
    application_code: "Project Application Code",
    sub_segment: "Project Segment",
    application: "Project Sub-Segment",
    platform: "Platform",
    actual_sale_price: "Price ($/lb)",
    city: "City",
    description: "Project Description",
    plants: "Selling Plant",
    lines: "Production Line",
    owner: "Supplier",
    brand: "Production Brand",
    country: "Country",
    nameplate: "Production Nameplate",
    customer: "Customer",
    customer_code: "Customer Code",
    customer_nickname: "Customer Nickname",
    customer_duns_number: 'D-U-N-S® Number',
    customer_owner: 'Customer Owner',
    company_is_sap: "SAP Companies",
    production_plant: "Production Plant",
    eop: "EOP",
    los_date: "Loss of Sales Date",
    created_at: "Date Submitted",
    updated_at: "Date Last Changed",
    sub_status_changed_at: "Change Status Date",
    program: "Program",
    grades: "Product Name",
    grade_sub_status: "Product Status",
    material_numbers: "Material Number",
    legacy_material_numbers: "Legacy Material Number",
    product_codes: "Product Code",
    grade_is_sap: "SAP Grades",
    product_application_codes: "SAP Application Code",
    slug: "Project ID",
    industry: "Project Market Segment",
    manager: "Sales Manager (Project)",
    internal_team: "Internal Team",
    name: "Project Name",
    manufacturer: "Manufacturer",
    region: "Region",
    move_to_current: "Start Date",
    end_date: "End Date",
    unit_value: "No. of Parts/Unit",
    sop: "SOP",
    tier1: "OEM Tier 1",
    tier1_nickname: "OEM Tier 1 Nickname",
    sub_status: "Project Status",
    stage_gate: "Salesforce Stage Gates",
    tier2: "OEM Tier 2",
    tier2_nickname: "OEM Tier 2 Nickname",
    unit: "Unit Forecast Name",
    molder: "OEM Tier 3",
    molder_nickname: "OEM Tier 3 Nickname",
    volume: "Volume",
    revenue: "Revenue",
    replacement_business_project_id: "Replacement Project ID",
    replacement_business_project_name: "Replacement Project Name",
    note: "Note",
};

export default tableColumns;