import {handleScroll} from "../../EventsService";
import {
    getItem,
    requestAssignToProduct,
    requestEditProduct,
    requestOnSaveAgreements,
    requestOnSaveCopyPublication
} from "./RequestService";
import {deleteItem, requestProductsTable} from "../../RequestService";
import {getActionStore, getStoreItem} from "../../StoreService";
import {getFilter, getTableLabel, isSortEnabled, getTooltip} from "../../TableHeaderService";
import {getViewItem, modifierValues} from "../../TableBodyService";

export default (id, subId) => ({
    requestProductsTable: requestProductsTable(`/admin/publications/${id}/technical/${subId}/grades`),
    getFilter: getFilter(),
    getViewItem,
    getTooltip,
    modifierValues,
    getTableLabel,
    isSortEnabled,
    requestSubTab: () => getItem(`/admin/publications/${id}/technical`),
    requestTypeItem: item => getItem(`/admin/publications/${id}/technical/${item.id}`),
    requestAssignToProduct: datasheetId => requestAssignToProduct(`/product/datasheets/${datasheetId}/copy`),
    requestEditProduct: (datasheetId, productId) => requestEditProduct(`/product/datasheets/${datasheetId}/edit-product-colors/${productId}`),
    requestOnSaveCopyPublication: datasheetId => requestOnSaveCopyPublication(`/admin/publications/${id}/datasheets/${datasheetId}/copy`),
    requestOnSaveAgreements: datasheetId => requestOnSaveAgreements(`/admin/publications/${id}/datasheets/${datasheetId}/agreements`),
    onClickDeleteItem: item => deleteItem(`/product/datasheets/${item.id}/delete`),
    onClickDeleteFromProductItem: productId => deleteItem(`/product/datasheets/${subId}/delete-from-product/${productId}`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getNotesData: (page) => getItem(`/admin/publications/${id}/technical/${subId}/notes`, {page}),
    getHistoryData: (sort, page) => getItem(`/admin/publications/${id}/technical/${subId}/history`, {sort, page}),
    rollbackHistory: (historyId) => getItem(`/admin/publications/${id}/technical/${subId}/history/${historyId}/rollback`),
});
