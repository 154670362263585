import React from "react";
import _ from "lodash";
import { InputFilter } from "Templates/Table/Filters";
import { onChange } from "../../EventsService";
import {gradeFileTypes, isSapImported, gradeStatus} from "Services/enum";

export const getTableLabel = (key, { currentFY }) => {
    const tableLabel = {
        name: "Grades",
        sub_status: "Product Status",
        plant_name: "Plants",
        material_numbers: "Material Numbers",
        legacy_product_numbers: "Legacy Product Number",
        count_projects: "Project(s)",
        count_invoices: "Invoice(s)",
        line_name: "Production Lines",
        owner: "Suppliers",
        product_codes: "Product Codes",
        files_count: "Count Files",
        submitted_by: "Submitted by",
        submitted_date: "Date Submitted",
        classification: "Material Types",
        volume: "Volume",
        revenue: "Revenue",
        gross_margin: "Gross Margin",
        publications: "File Type",
        sap_plant: "Sap Plants",
        sap_line: "Sap Production Lines",
        date_update: "Date Updated",
        sap_value: "SAP Import",
    };
    switch (key) {
        case "volume":
        case "revenue":
        case "gross_margin":
            return `FY ${currentFY}  ${_.get(tableLabel, key, key)}`;

        default:
            return _.get(tableLabel, key, key);
    }
};

export const getFilter = id => (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        plant_name: "Search",
        material_numbers: "Search",
        legacy_product_numbers: "Search",
        count_projects: "Search",
        count_invoices: "Search",
        line_name: "Search",
        owner: "Search",
        product_codes: "Search",
        files_count: "Search",
        submitted_by: "Search",
        submitted_date: "Date Submitted",
        classification: "Search",
        revenue: "Search",
        volume: "Search",
        gross_margin: "Search",
        margin: "Search",
        publications: "Select",
        sub_status: "Select",
        date_update: "Date Updated",
        sap_value: "Search",
        sap_plant: "Search",
        sap_line: "Search",
    };
    switch (key) {
        case "sap_value":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...isSapImported]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sub_status":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[...[{value: '', label: "-"}],...gradeStatus]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sap_plant":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/plant-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sap_line":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/sap-line-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "product_type":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/product/product-finder/product-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "publications":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={[...gradeFileTypes]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "classification":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`/product/products/${id}/grades/classification-list`}
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "date_update":
        case "submitted_date":
            return (
                <InputFilter
                    type="date-range"
                    viewFormat="MMM DD, YYYY"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "revenue":
        case "volume":
        case "gross_margin": {
            return (
                <InputFilter disabled type="text" placeholder="" value="" onChange={() => {}} />
            );
        }

        case "owner":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={`/product/products/${id}/grades/owner-list`}
                    name="owner"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};
