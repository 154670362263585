import React from "react";
import {diffDate, formatDate, getString} from "Services";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import {companyPhoneTypes} from "Services/enum";
import _ from "lodash";
import TableRowItemTooltip from "Templates/Table/TableRowItemTooltip";

const getDunsNumberData = () => {
    return 'D-U-N-S&#174; Number'
}

const tableLabel = {
    name: "Company Name",
    short_name: "Company Short Name",
    duns_number: getDunsNumberData(),
    slug: "Customer ID",
    customer_code: "Customer Code",
    legacy_customer_code: "Legacy Customer Code",
    customer_owner: "Customer Owner",
    nicknames: "Nickname",
    phone: "Phone Number",
    project_count: "Project(s)",
    invoice_count: "Invoice(s)",
    email: "Email",
    nda: "NDA",
    notes: "Notes",
    supply_chain: "Supply Chain",
    address1: "Address",
    city: "City",
    country: "Country",
    region_code: "Region/State ABR",
    state: "Region/State",
    district: "District/County",
    zip: "Zip Code",
    time_zone: "Time Zone",
    changed_date: "Date Changed",
    updated_date: "Date Updated in ComOpps",
    created_code_date: "Date Created/Extended",
    created_system_date: "Date Created",
    owner: "Owner",
    sap_value: "SAP Import",
    corporate_group: "Corporate Group",
    marked_deletion: "Deletion Marker",
};

export const title = "Companies";

export const getTableLabel = key => getString(tableLabel, key, key);

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    switch (key) {
        case "name":
            return (
                <TableRowItemLink blank title={getString(item, key)} href={`/companies/${getString(item, "id")}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "email":
            return (
                <TableRowItemLink blank title={getString(item, key)} href={`mailto:${getString(item, key)}`}>
                    {getString(item, key)}
                </TableRowItemLink>
            );

        case "nda":
            const value = getString(item, key);
            if (!value) {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }
            const date = diffDate(value, false, "month");

            let dateColor = "";
            switch (true) {
                case date < 3:
                    dateColor = "clr-red";
                    break;

                case date < 6:
                    dateColor = "clr-yellow";
                    break;
            }

            return (
                <TableRowItemSpan title={formatDate(value)} className={dateColor}>
                    {formatDate(value)}
                </TableRowItemSpan>
            );

        case "nicknames": {
            const value = getString(item, key);

            if (!value || value === '-') {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }
            const arrDate = JSON.parse(getString(item, key));

            return (
                <>
                    {arrDate.map((elem, index) => (
                        <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={elem}>
                            {elem}
                        </TableRowItemSpan>
                    ))}
                </>
            );
        }

        case "marked_deletion":
        case "sap_value":
            return <TableRowItemSpan>{getString(item, key) ? 'YES' : 'NO'}</TableRowItemSpan>;

        case "updated_date":
        case "changed_date":
        case "created_code_date":
        case "created_system_date":
            const dateItem = _.get(item, key) !== null ? formatDate(_.get(item, key)) : '-';

            return (
                <TableRowItemSpan title={dateItem}>{dateItem}</TableRowItemSpan>
            );

        case "phone":
            const phones = item.phone ? JSON.parse(item.phone) : [];

            if (!phones.length) {
                return <TableRowItemSpan>-</TableRowItemSpan>;
            }

            return (
                <>
                    {phones.map((elem, index) => (
                        <TableRowItemSpan title={elem.number} key={`TableRowItemSpan-${elem.number}`}>
                            {`${_.find(companyPhoneTypes, {value:`${elem.type}`}).label}: ${elem.number}`}
                        </TableRowItemSpan>
                    ))}
                </>
            );

        case 'notes':
            return (
                item.notes
                    ? <div className="publication-row"><TableRowItemTooltip>{item.notes}</TableRowItemTooltip></div>
                    : <TableRowItemSpan title="-">-</TableRowItemSpan>
            );

        default:
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
