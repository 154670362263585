import {debounce} from "Services/Debounce";
import {requestExportProjects} from "../Services";
import Constants from "../Constants";
import {parseFilters} from "../Constants/tableFiltersList";

const requestFailed = data => ({ type: Constants.FETCH_ITEMS_FAILED, data });

const requestItem = () => ({ type: Constants.FETCH_ITEMS_REQUESTED, data: { loading: true } });

const requestSucceeded = data => ({ type: Constants.FETCH_ITEMS_SUCCEEDED, data });

const requestNextPageSucceeded = data => ({ type: Constants.NEXT_PAGE, data });

export const fetchItems = (service, dispatch) => data => {
    dispatch(requestItem());

    const { reset, sort } = data;
    let filters = {}

    if (window) {
        const searchUrl = new URL(window.location);
        const filtersString = searchUrl.searchParams.get('filters');

        if (filtersString && !reset) {
            filters = parseFilters(JSON.parse(filtersString))
        } else {
            filters = {}
        }
    }

    const { requestTable } = service;

    requestTable({ filters, sort }).then(
        res => {
            dispatch(requestSucceeded({ ...res, sort: { ...(sort || {}) } }));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};

export const applyFilters = (service, dispatch) => (data) => {
    dispatch({type: Constants.APPLY_FILTERS, data});
}

export const getUrlExport = (service, dispatch) => (data) => {
  dispatch({type: Constants.EXPORT_PROJECTS, data});

  return requestExportProjects(data).then(
      res => {
          alert('Export Projects started. Check notifications to download.');
          dispatch({ type: Constants.EXPORT_PROJECTS_SUCCESS, data: res });
      },
      res => {
          alert(res.detail);
          dispatch({ type: Constants.EXPORT_PROJECTS_FAILED, data: res });
      },
  );
};

export const onResetProjectFilters = (service, dispatch) => data => {
    dispatch({ type: Constants.RESET_PROJECT_FILTERS, data });
};

export const activeWeightUnit = (service, dispatch) => data => {
    dispatch({type: Constants.IS_ACTIVE_COVERTOR, data});
};

export const onChange = (service, dispatch) => (key, value, request) => {
    dispatch({ type: Constants.CHANGE_FILTER, data: { key, value } });

    if (
        [
            "slug",
            "name",
            "products",
            "grades",
            "material_numbers",
            "legacy_material_numbers",
            "product_codes",
            "product_application_codes",
            "internal_team",
            "plants",
            "lines",
            "brand",
            "nameplates",
            "sub_status",
            "stage_gate",
            "application_code",
            "sub_segment",
            "application",
            "industry",
            "customer",
            "customer_code",
            "customer_nickname",
            "customer_duns_number",
            "customer_owner",
            "tier1",
            "tier1_nickname",
            "tier2",
            "tier2_nickname",
            "molder",
            "molder_nickname",
            "status",
            "budget",
            "status",
            "manager",
            "owner",
            "unit_value",
            "weight_value",
            "program",
            "city",
            "country",
            "region",
            "production_plant",
            "unit",
            "manufacturer",
            "replacement_business_project_id",
            "replacement_business_project_name",
            "platform",
            "program",
            "city",
            "country",
            "region",
            "production_plant",
            "unit",
            "company_is_sap",
            "grade_is_sap",
            "los_date",
            "created_at",
            "updated_at",
            "sub_status_changed_at",
            "sop",
            "eop",
            "grade_sub_status",
            "end_date",
            "move_to_current"
        ].includes(key)
    ) {
        debounce(() => fetchItems(service, dispatch)(request));
        return;
    }

    if (value.length >= 2) {
        debounce(() => fetchItems(service, dispatch)(request));
    }
};

export const resetFilter = (service, dispatch) => (request) => {
    dispatch({ type: Constants.RESET_FILTER, data: {} });
    fetchItems(service, dispatch)(request);
};

export const fetchPage = (service, dispatch) => data => {
    dispatch(requestItem());

    const { reset, sort } = data;
    let filters = {}

    if (window) {
        const searchUrl = new URL(window.location);
        const filtersString = searchUrl.searchParams.get('filters');

        if (filtersString && !reset) {
            filters = parseFilters(JSON.parse(filtersString))
        } else {
            filters = {}
        }
    }

    const { requestTable } = service;

    requestTable({ filters, sort }).then(
        res => {
            dispatch(requestNextPageSucceeded(res));
        },
        res => {
            dispatch(requestFailed(res));
        },
    );
};
