import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import { ServiceProvider } from "Services/Context";
import Header from "App/View/Header";
import { Content } from "App/View/Content";
import {compose} from "redux";
import {withTagDefaultProps} from "Hoc/Template";

const propTypes = {
    service: PropTypes.shape({
        getStoreItem: PropTypes.func.isRequired,
    }).isRequired,
};

const AuthorizedUserWrapper = ({ service, children, alerts, isInternalUser }) => {
    const headerRef = useRef(null);
    const [headerHeight, setHeaderHeight] = useState(null);
    const [withAlerts, setWithAlerts] = useState(true);
    const [mainContentHeight, setMainContentHeight] = useState('100%')

    useLayoutEffect(() => {
        handleCloseAlerts();
    }, [alerts])

    useEffect(() => {
        if (!headerRef.current) {
            return;
        }
        const resizeObserver = new ResizeObserver((e) => {
            const height = headerRef.current.clientHeight
            setHeaderHeight(height);

            if (e && e.contentRect && e.contentRect.width && e.contentRect.width <= 1255) {
                setMainContentHeight(withAlerts && isInternalUser && height ? `calc(100% - ${height}px)` : `calc(100% - 10.4rem)`)
            } else {
                setMainContentHeight(withAlerts && isInternalUser && height ? `calc(100% - ${height}px)` : `calc(100% - 5.6rem)`)
            }
        });
        resizeObserver.observe(headerRef.current);
        return () => resizeObserver.disconnect();
    }, []);

    const handleCloseAlerts = () => {
        if (!alerts || !alerts.length) {
            setWithAlerts(false)
        }
        if (typeof document !== 'undefined') {
            if (alerts && alerts.length) {
                const alertsFromStorage = localStorage.getItem('viewedAlerts');
                if (!alertsFromStorage) return
                const viewAlerts = JSON.parse(alertsFromStorage || '');
                const existingAlerts = alerts.reduce((acc, curr) => {
                    if (viewAlerts.includes(curr.id)) {
                        acc.push(curr.id)
                    }

                        return acc
                }, [])

                if (existingAlerts.length !== alerts.length) {
                    setWithAlerts(true)
                } else {
                    setWithAlerts(false)
                }
            }
        }
    }

    return (
        <div className={`main-wrap ${withAlerts && isInternalUser ? 'with-alerts' : ''}`}>
            <ServiceProvider value={{ getStoreItem: service.getStoreItem }}>
                <Header ref={headerRef} handleCloseAlerts={handleCloseAlerts}/>
            </ServiceProvider>
            <Content
                service={service}
                headerHeight={headerHeight}
                mainContentHeight={mainContentHeight}
            >{children}</Content>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        alerts: state.appState.alerts || [],
        isInternalUser: state.userState && state.userState.isInternalUser ? state.userState.isInternalUser : false
    };
};

const mapDispatchToProps = (dispatch, { service }) => {
    return {};
};AuthorizedUserWrapper.propTypes = propTypes;

export default compose(withTagDefaultProps(mapStateToProps, mapDispatchToProps))(AuthorizedUserWrapper);
