export const isFiltersList = [
    'slug',
    'name',
    'owner',
    'product',
    'grades',
    'material_numbers',
    'legacy_material_numbers',
    'company_is_sap',
    'grade_is_sap',
    'grade_sub_status',
    'product_codes',
    'product_application_codes',
    'plants',
    'lines',
    'internal_team',
    'brand',
    'nameplate',
    'sub_status',
    'stage_gate',
    'status',
    'budget',
    'application_code',
    'sub_segment',
    'application',
    'manufacturer',
    'platform',
    'program',
    'city',
    'country',
    'region',
    'production_plant',
    'unit',
    'industry',

    'move_to_current',
    'sop',
    'eop',
    'los_date',
    'created_at',
    'updated_at',
    'sub_status_changed_at',

    'customer',
    'tier1',
    'tier2',
    'molder',
    'customer_code',
    'customer_duns_number',
    'customer_nickname',
    'manager',
    'replacement_business_project_id',
    'replacement_business_project_name',
];

export const onlyYesNoFilters = [
    'status',
    'budget',
    'weight_value',
    'actual_sale_price',
    'company_is_sap',
    'grade_is_sap',
    'sub_status',
    'grade_sub_status',
    'stage_gate',
    'industry',
]

export const datesFilters = [
    'move_to_current',
    'sop',
    'eop',
    'los_date',
    'created_at',
    'updated_at',
    'move_to_current',
    'end_date',
    'sub_status_changed_at',
]

export const emptyNotAllowedFilters = [
    'slug',
    'name',
    'customer',
    'grades',
    'actual_sale_price',
    'application_code',
    'application',
    'weight_value',
    'unit_value',
]

export const tableColumnsFiltersList = [
    {
        label: "Added to ComOpps Forecast",
        value: 'status'
    },
    {
        label: "Added to Budget & Sales Forecast",
        value: 'budget'
    },
    {
        label: "Part Weight (lbs)",
        value: 'weight_value'
    },
    {
        label: "Project Application Code",
        value: 'application_code'
    },
    {
        label: "Project Segment",
        value: 'sub_segment'
    },
    {
        label: "Project Sub-Segment",
        value: 'application'
    },
    {
        label: "Platform",
        value: 'platform'
    },
    {
        label: "Price ($/lb)",
        value: 'actual_sale_price'
    },
    {
        label: "City",
        value: 'city'
    },
    {
        label: "Selling Plant",
        value: 'plants'
    },
    {
        label: "Production Line",
        value: 'lines'
    },
    {
        label: "Supplier",
        value: "owner"
    },
    {
        label: "Production Brand",
        value: "brand"
    },
    {
        label: "Country",
        value: "country"
    },
    {
        label: "Production Nameplate",
        value: "nameplate"
    },
    {
        label: "Customer",
        value: "customer"
    },
    {
        label: "Customer Code",
        value: "customer_code"
    },
    {
        label: "Customer Nickname",
        value: "customer_nickname"
    },
    {
        label: "D-U-N-S® Number",
        value: 'customer_duns_number'
    },
    {
        label: "SAP Companies",
        value: "company_is_sap"
    },
    {
        label: "Production Plant",
        value: "production_plant"
    },
    {
        label: "EOP",
        value: "eop"
    },
    {
        label: "Loss of Sales Date",
        value: "los_date"
    },
    {
        label: "Date Submitted",
        value: "created_at"
    },
    {
        label: "Start Date",
        value: "move_to_current"
    },
    {
        label: "End Date",
        value: "end_date"
    },
    {
        label: "Date Last Changed",
        value: "updated_at"
    },
    {
        label: "Change Status Date",
        value: "sub_status_changed_at"
    },
    {
        label: "Program",
        value: "program"
    },
    {
        label: "Product Name",
        value: "grades"
    },
    {
        label: "Product Status",
        value: "grade_sub_status"
    },
    {
        label: "Material Number",
        value: "material_numbers"
    },
    {
        label: "Legacy Material Number",
        value: "legacy_material_numbers"
    },
    {
        label: "Product Code",
        value: "product_codes"
    },
    {
        label: "SAP Grades",
        value: "grade_is_sap"
    },
    {
        label: "SAP Application Code",
        value: "product_application_codes"
    },
    {
        label: "Project ID",
        value: "slug"
    },
    {
        label: "Project Market Segment",
        value: "industry"
    },
    {
        label: "Sales Manager (Project)",
        value: "manager"
    },
    {
        label: "Internal Team",
        value: "internal_team"
    },
    {
        label: "Project Name",
        value: "name"
    },
    {
        label: "Manufacturer",
        value: "manufacturer"
    },
    {
        label: "Region",
        value: "region"
    },
    {
        label: "Move to Current",
        value: "move_to_current"
    },
    {
        label: "No. of Parts/Unit",
        value: "unit_value"
    },
    {
        label: "SOP",
        value: "sop"
    },
    {
        label: "OEM Tier 1",
        value: "tier1"
    },
    {
        label: "Project Status",
        value: "sub_status"
    },
    {
        label: "Salesforce Stage Gates",
        value: "stage_gate"
    },
    {
        label: "OEM Tier 2",
        value: "tier2"
    },
    {
        label: "Unit Forecast Name",
        value: "unit"
    },
    {
        label: "OEM Tier 3",
        value: "molder"
    },
    {
        label: "Replacement Project ID",
        value: "replacement_business_project_id"
    },
    {
        label: "Replacement Project Name",
        value: "replacement_business_project_name"
    },
    {
        label: "Note",
        value: "note"
    },
    {
        label: "Project Description",
        value: "description"
    }
];

export const AllTableColumns = {
    status: "Added to ComOpps Forecast",
    budget: "Added to Budget & Sales Forecast",
    weight_value: "Part Weight (lbs)",
    application_code: "Project Application Code",
    sub_segment: "Project Segment",
    application: "Project Sub-Segment",
    platform: "Platform",
    actual_sale_price: "Price ($/lb)",
    city: "City",
    plants: "Selling Plant",
    lines: "Production Line",
    owner: "Supplier",
    brand: "Production Brand",
    country: "Country",
    nameplate: "Production Nameplate",
    customer: "Customer",
    customer_code: "Customer Code",
    customer_nickname: "Customer Nickname",
    customer_duns_number: "D-U-N-S® Number",
    company_is_sap: "SAP Companies",
    production_plant: "Production Plant",
    eop: "EOP",
    los_date: "Loss of Sales Date",
    created_at: "Date Submitted",
    updated_at: "Date Last Changed",
    sub_status_changed_at: "Change Status Date",
    program: "Program",
    grades: "Product Name",
    grade_sub_status: "Product Status",
    material_numbers: "Material Number",
    legacy_material_numbers: "Legacy Material Number",
    product_codes: "Product Code",
    grade_is_sap: "SAP Grades",
    product_application_codes: "SAP Application Code",
    slug: "Project ID",
    industry: "Project Market Segment",
    manager: "Sales Manager (Project)",
    internal_team: "Internal Team",
    name: "Project Name",
    manufacturer: "Manufacturer",
    region: "Region",
    move_to_current: "Move to Current",
    unit_value: "No. of Parts/Unit",
    sop: "SOP",
    tier1: "OEM Tier 1",
    tier1_nickname: "OEM Tier 1 Nickname",
    sub_status: "Project Status",
    stage_gate: "Salesforce Stage Gates",
    tier2: "OEM Tier 2",
    tier2_nickname: "OEM Tier 2 Nickname",
    unit: "Unit Forecast Name",
    molder: "OEM Tier 3",
    molder_nickname: "OEM Tier 3 Nickname",
    note: "Note"
}

export const tableColumnsFiltersOperators = [
    {
        label: "Is",
        value: "is"
    },
    {
        label: "Contains",
        value: "_contains"
    },
    {
        label: "Is Empty",
        value: "empty"
    },
    {
        label: "Is Not Empty",
        value: "not_empty"
    },
]

export const FiltersColumnsUrls = {
    slug: "/project/projects/project-slug",
    name: "/project/projects/project-list",
    owner: "/project/projects/owner-list",
    product: "/project/projects/product-list",
    grades: "/project/projects/grade-list",
    material_numbers: "/project/projects/material-number-list",
    legacy_material_numbers: "/project/projects/legacy-material-number-list",
    product_codes: "project/projects/product-code-list",
    product_application_codes: "/project/projects/application-code-list",
    plants: "/project/projects/plant-list",
    lines: "/project/projects/line-list",
    internal_team: "/project/projects/user-list",
    brand: "/project/projects/brand-list",
    nameplate: "/project/projects/nameplate-list",
    application_code: "/project/projects/application-code-list",
    sub_segment: "/project/projects/sub-segment-list",
    application: "/project/projects/application-list",
    manufacturer: "/project/projects/manufacturer-forecast-list",
    platform: "/project/projects/platform-list",
    program: "/project/projects/program-list",
    city: "/project/projects/city-list",
    country: "/project/projects/country-list",
    region: "/project/projects/region-list",
    production_plant: "/project/projects/production-plant-list",
    unit: "/project/projects/unit-list",
    customer: "/project/projects/customer-list",
    tier1: "/project/projects/customer-list",
    tier2: "/project/projects/customer-list",
    molder: "/project/projects/customer-list",
    customer_code: "/project/projects/customer-code-list",
    customer_duns_number: "/project/projects/customer-duns-list",
    customer_nickname: "/project/projects/customer-nickname-list",
    tier1_nickname: "/project/projects/customer-nickname-list",
    tier2_nickname: "/project/projects/customer-nickname-list",
    molder_nickname: "/project/projects/customer-nickname-list",
    manager: "/project/projects/user-list",
    replacement_business_project_id: '/project/projects/project-slug',
    replacement_business_project_name: '/project/projects/project-list',
}

export const parseFilters = (filtersItems) => {
    let filters = {}

    filtersItems.forEach((filterItem) => {
        const listFilters = [
            'replacement_business_project_id',
            'replacement_business_project_name',
            'sub_segment',
            'nameplate',
            'brand',
            'city',
            'platform',
            'tier1',
            'tier2',
            'molder',
            'manufacturer',
            'program',
            'stage_gate',
            'customer',
            'customer_code',
            'customer_duns_number',
            'owner',
            'application_code',
            'industry',
            'sub_segment',
            'application',
            'grades',
            'grade_sub_status',
            'material_numbers',
            'legacy_material_numbers',
            'product_codes',
            'product_application_codes',
            'plants',
            'lines',
            'unit',
            'manager',
            'internal_team',
            'country',
            'region',
            'production_plant',
            'slug',
            'name',
        ];
        const containsListFilters = [
            'city',
            'platform',
            'nameplate',
            'manufacturer',
            'replacement_business_project_name',
            'replacement_business_project_id',
            'program',
            'brand',
            'customer',
            'customer_code',
            'customer_duns_number',
            'tier1',
            'tier2',
            'molder',
            'owner',
            'application_code',
            'sub_segment',
            'application',
            'grades',
            'grade_sub_status',
            'material_numbers',
            'legacy_material_numbers',
            'product_codes',
            'product_application_codes',
            'plants',
            'lines',
            'unit',
            'manager',
            'internal_team',
            'country',
            'region',
            'production_plant',
            'note',
            'slug',
            'name',
            'description',
        ]

        if (filterItem.operator === filterItem.operator) {
            switch (filterItem.operator) {
                case '_contains':
                    if (filters[`filter[${filterItem.filter}_contains]`]) {
                        filters[`filter[${filterItem.filter}_contains]`].push(...(containsListFilters.includes(filterItem.filter) || Array.isArray(filterItem.value) ? [filterItem.value] : filterItem.value));
                    } else {
                        filters[`filter[${filterItem.filter}_contains]`] = containsListFilters.includes(filterItem.filter) || Array.isArray(filterItem.value) ? [filterItem.value] : filterItem.value;
                    }
                    break;
                case 'empty':
                    if (listFilters.includes(filterItem.filter)) {
                        filters[`filter[${filterItem.filter}][]`] = 'empty';
                    } else {
                        filters[`filter[${filterItem.filter}]`] = 'empty';
                    }
                    break;
                case 'not_empty':
                    if (listFilters.includes(filterItem.filter)) {
                        filters[`filter[${filterItem.filter}][]`] = 'not_empty';
                    } else {
                        filters[`filter[${filterItem.filter}]`] = 'not_empty';
                    }
                    break;
                default:
                    if (filterItem.operator !== '_contains') {
                        filters[`filter[${filterItem.filter}]`] = filterItem.value;
                    }
                    break;
            }
        }
    });

    return filters
}
