import React from "react";
import {useSelector} from 'react-redux';
import _ from "lodash";
import {url} from "Services";
import {industryOptions, projectStatusActive, projectStatusArchived} from "Services/enum";
import {InputFilter} from "Templates/Table/Filters";
import {weightUnitConvertLbs, weightUnitName} from 'Services/weightUnitName';
import {onChange} from "../../EventsService";
import tableColumns from "./Constants/tableColumns";
import tabList from "./Constants/tabList";

export const getTableLabel = (key, {currentFY}) => {
    const unit = useSelector(state => state.weightUnitState);

    const tableLabel = {
        ...tableColumns,
        weight_value: `Part Weight (${weightUnitConvertLbs(weightUnitName(unit.weightUnit))})`,
        actual_sale_price: `Price ($/${weightUnitName(unit.weightUnit)})`,
    };

    switch (key) {
        case "volume":
        case "revenue":
        case "gross_margin":
            return `FY ${currentFY}  ${_.get(tableLabel, key, key)}`;

        case String(key.match(/^volume_.*/)):
        case String(key.match(/^revenue_.*/)):
        case String(key.match(/^grossMargin_.*/)): {
            const keys = key.split("_");
            return `${keys[1]} ${keys[2]} <br/> Forecast ${_.get(tableLabel, keys[0], key)}`;
        }
        default:
            return _.get(tableLabel, key, key);
    }
};

export const getFilter = (key, value, callback, request) => {
    const placeholders = {
        slug: "Search",
        name: "Search",
        sub_status: "Select",
        application: "Search",
        application_code: "Search",
        customer: "Search",
        customer_code: "Search",
        industry: "Search",
        manager: "Select",
        grade: "Search",
        grades: "Search",
        material_number: "Search",
        material_numbers: "Search",
    };

    switch (key) {
        case "name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/project-list/archived"
                    name="name"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "slug":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/project-slug/archive"
                    name="slug"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "application_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/application-code-list"
                    name="application_code"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "customer_code":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/customer-code-list"
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "industry":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={industryOptions}
                    placeholder={_.get(placeholders, key)}
                    name="industry"
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "application":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/application-list"
                    name="application"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={
                        url.getActiveTab().toString() === tabList.archive.toString() ? projectStatusArchived : [...projectStatusActive]
                    }
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "manager":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    url="/project/projects/user-list"
                    value={value}
                    placeholder={_.get(placeholders, key)}
                    name="manager"
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "grade":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/grade-list"
                    name="grade"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("grades", _value, request));
                    }}
                />
            );

        case "material_numbers":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/material-number-list"
                    name="material_numbers"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("material_numbers", _value, request));
                    }}
                />
            );

        case "legacy_material_numbers":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url="/project/projects/legacy-material-number-list"
                    name="legacy_material_number"
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback("legacy_material_numbers", _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const isSortEnabled = key => !(/^volume_.*/.test(key) || /^revenue_.*/.test(key) || /^grossMargin_.*/.test(key));
