import React from "react";
import _ from "lodash";
import {InputFilter} from "Templates/Table/Filters";
import {gradeStatus, colorSources} from "Services/enum";
import {onChange} from "./EventsService";

export const getTableLabel = (key) => {
    const tableLabel = {
        name: "Product Name",
        sub_status: "Product Status",
        material_number: "Material Number",
        legacy_product_number: "Legacy Material Number",
        count_projects: "Project(s)",
        count_invoices: "Invoices(s)",
        sap_plant: "SAP Plant",
        sap_line: "SAP Production Line",
        color: "Color",
        approval_name: "OEM Approvals",
        code_name: "Color Code",
        source_name: "Source",
        user_name: "Assigned by",
    };

    switch (key) {
        default:
            return _.get(tableLabel, key, key);
    }
};

export const getFilter = () => (key, value, callback, request) => {
    const placeholders = {
        name: "Search",
        sub_status: "Search",
        material_number: "Search",
        legacy_product_number: "Search",
        count_projects: "Search",
        count_invoices: "Search",
        sap_plant: "Search",
        sap_line: "Search",
        color: "Search",
        approval_name: "Search",
        code_name: "Search",
        source_name: "Select",
        user_name: "Search",
    };

    const urls = {
        sap_plant: "/product/product-finder/plant-list",
        sap_line: "/product/product-finder/sap-line-list",
        color: "/colors/color-list",
        approval_name: "/colors/color-approvals-list",
        code_name: "/colors/color-code-list",
        user_name: "/product/users/user-list",
    };

    switch (key) {
        case "approval_name":
        case "code_name":
        case "sap_plant":
        case "sap_line":
        case "color":
        case "user_name":
            return (
                <InputFilter
                    type="asyncMultiSelect"
                    placeholder={_.get(placeholders, key)}
                    url={urls[key]}
                    name={key}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "sub_status":
            return (
                <InputFilter
                    type="multiSelect"
                    value={value}
                    options={[...gradeStatus]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        case "source_name":
            return (
                <InputFilter
                    type="select"
                    value={value}
                    options={[{value: '', label: "-"}, ...colorSources]}
                    placeholder={_.get(placeholders, key)}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );

        default:
            return (
                <InputFilter
                    type="text"
                    placeholder={_.get(placeholders, key)}
                    value={value}
                    onChange={e => {
                        onChange(e).then(_value => callback(key, _value, request));
                    }}
                />
            );
    }
};

export const getTooltip = key => {
    const tableTooltip = {
        sub_status: `
            <div class="long-tooltip"><span class="font-medium">WIP</span> - Work In Progress. Product not for sale.</div>
            <div class="long-tooltip"><span class="font-medium">Trial</span> - Use for samples, development, limit lots, production trials and approvals.</div>
            <div class="long-tooltip"><span class="font-medium">Production Intent</span> - Product in process for being approved.</div>
            <div class="long-tooltip"><span class="font-medium">Production Viable</span> - Product produced and tested. Did not go to production but is a viable product that could be sold.</div>
            <div class="long-tooltip"><span class="font-medium">Production</span> - Product available for sale. Use for production and/or samples.</div>
            <div class="long-tooltip"><span class="font-medium">Obsolete</span> - Newer product versions should be available. This product may not be available for sale.</div>
        `,
    };

    return _.get(tableTooltip, key, null);
}

export const isSortEnabled = key => !(/^color.*/.test(key) || /^approval_name.*/.test(key) || /^code_name.*/.test(key) || /^source_name.*/.test(key));