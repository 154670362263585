import {handleScroll} from "../../EventsService";
import {getItem, requestAssignToProduct, requestOnSaveAgreements, requestOnSaveCopyPublication} from "./RequestService";
import {getActionStore, getStoreItem} from "../../StoreService";
import {deleteItem, requestProductsTable} from "../../RequestService";
import {getFilter, getTableLabel, isSortEnabled, getTooltip} from "../../TableHeaderService";
import {getViewItem, modifierValues} from "../../TableBodyService";
import {requestEditProduct} from "../Technical/RequestService";

export default (id, subId) => ({
    requestProductsTable: requestProductsTable(`/admin/publications/${id}/design/${subId}/grades`),
    getFilter: getFilter(),
    getViewItem,
    modifierValues,
    getTableLabel,
    isSortEnabled,
    getTooltip,
    requestSubTab: () => getItem(`/admin/publications/${id}/design`),
    requestTypeItem: item => getItem(`/admin/publications/${id}/design/${item.id}`),
    requestAssignToProduct: (designId) => requestAssignToProduct(`/product/designs/${designId}/copy`),
    requestEditProduct: (datasheetId, productId) => requestEditProduct(`/product/designs/${datasheetId}/edit-product-colors/${productId}`),
    requestOnSaveCopyPublication: (designId) => requestOnSaveCopyPublication(`/admin/publications/${id}/designs/${designId}/copy`),
    requestOnSaveAgreements: (designId) => requestOnSaveAgreements(`/admin/publications/${id}/designs/${designId}/agreements`),
    onClickDeleteItem: item => deleteItem(`/product/designs/${item.id}/delete`),
    onClickDeleteFromProductItem: productId => deleteItem(`/product/designs/${subId}/delete-from-product/${productId}`),
    handleScroll,
    getStoreItem,
    getActionStore,
    getNotesData: (page) => getItem(`/admin/publications/${id}/design/${subId}/notes`, { page }),
    getHistoryData: (sort, page) => getItem(`/admin/publications/${id}/design/${subId}/history`, { sort, page }),
    rollbackHistory: (historyId) => getItem(`/admin/publications/${id}/design/${subId}/history/${historyId}/rollback`),
});
