import React from "react";
import {formatDate, getString, numberWithCommas} from "Services";
import {gradeStatus, projectConsistent, projectStatus} from "Services/enum";
import TableRowItemSpan from "Templates/Table/TableRowItemSpan";
import TableRowItemLink from "Templates/Table/TableRowItemLink";
import TableRowItemStatus from "Templates/Table/TableRowItemStatus";
import {useSelector} from "react-redux";
import {weightUnitConvertLbs, weightUnitName} from 'Services/weightUnitName';

export const title = "Projects";

export const getTableLabel = key => {
    const unit = useSelector(state => state.weightUnitState);

    const tableLabel = {
        slug: "Project ID",
        name: "Project Name",
        owner: "Company",
        application_code: "Project Application Code",
        customer_duns_number: "D-U-N-S® Number",
        note: "Note",
        customer_owner: "Customer Owner",
        sub_status: "Status",
        industry: "Project Market Segment",
        sub_segment: "Project Segment",
        application: "Project Sub-Segment",
        product: "Brand",
        grades: "Product Name",
        material_numbers: "Material Number",
        legacy_material_numbers: "Legacy Material Number",
        product_codes: "Product Code",
        product_application_codes: "SAP Application Code",
        status: "Added to ComOpps Forecast",
        budget: "Added to Budget & Sales Forecast",
        replacement_business_project_id: "Replacement Project ID",
        replacement_business_project_name: "Replacement Project Name",
        plants: "Selling Plant",
        lines: "Production Line",
        customer: "Customer",
        customer_code: "Customer Code",
        customer_nickname: "Customer Nickname",
        manufacturer: "Manufacturer",
        tier1: "OEM Tier 1",
        tier1_nickname: "OEM Tier 1 Nickname",
        tier2: "OEM Tier 2",
        tier2_nickname: "OEM Tier 2 Nickname",
        molder: "OEM Tier 3",
        molder_nickname: "OEM Tier 3 Nickname",
        unit: "Unit Forecast Name",
        sop: "SOP",
        move_to_current: "Start Date",
        end_date: "End Date",
        eop: "EOP",
        manager: "Project Manager",
        internal_team: "Internal Team",
        platform: "Platform",
        program: "Program",
        brand: "Production Brand",
        nameplate: "Production Nameplate",
        country: "Country",
        region: "Region",
        production_plant: "Production Plant",
        city: "City",
        description: "Project Description",
        volume: "Volume",
        revenue: "Revenue",
        gross_margin: "Gross Margin",
        grossMargin: "Gross Margin",
        actual_sale_price: `Price ($/${weightUnitName(unit.weightUnit)})`,
        weight_value: `Part Weight (${weightUnitConvertLbs(weightUnitName(unit.weightUnit))})`,
        unit_value: "No. of Parts/Unit",
        los_date: "Loss of Sales Date",
        created_at: "Date Submitted",
        updated_at: "Date Last Changed",
        sub_status_changed_at: "Change Status Date",
        company_is_sap: "SAP Companies",
        grade_is_sap: "SAP Grades",
        grade_sub_status: "Product Status",
        stage_gate: "Salesforce Stage Gates",
    };


    switch (key) {
        case "volume":
        case "revenue":
        case "gross_margin":
            return `FY ${currentFY} ${getString(tableLabel, key, key)}`;

        case String(key.match(/^volume_.*/)):
        case String(key.match(/^revenue_.*/)):
        case String(key.match(/^grossMargin_.*/)): {
            const keys = key.split("_");
            return `FY ${keys[1]} ${getString(tableLabel, keys[0], key)}`;
        }
        default:
            return getString(tableLabel, key, key);
    }
};

export const modifierValues = items => items;

export const getViewItem = (key, item) => {
    const tableRowItemLink = (labelKey, href) => (
        <TableRowItemLink blank title={getString(item, labelKey)} href={href}>
            {getString(item, labelKey)}
        </TableRowItemLink>
    );
    switch (key) {
        case "slug":
            return tableRowItemLink("slug", `/project/projects/${getString(item, "id")}`);

        case "name":
            return tableRowItemLink("name", `/project/projects/${getString(item, "id")}`);

        case "replacement_business_project_id": {
            const replacementBusinessItems = item.replacement_business_projects
            return (
                <>
                    {replacementBusinessItems && replacementBusinessItems.length ? replacementBusinessItems.map((elem, index) => (
                        <TableRowItemLink blank
                                          title={getString(item, key)}
                                          href={`/project/projects/${elem.id}`}
                        >
                            {elem.slug}
                        </TableRowItemLink>
                    )) : '-'}
                </>
            );
        }

        case "replacement_business_project_name": {
            const replacementBusinessItems = item.replacement_business_projects
            return (
                <>
                    {replacementBusinessItems && replacementBusinessItems.length ? replacementBusinessItems.map((elem, index) => (
                        <TableRowItemLink blank
                                          title={getString(item, key)}
                                          href={`/project/projects/${elem.id}`}
                        >
                            {elem.name}
                        </TableRowItemLink>
                    )) : '-'}
                </>
            );
        }

        case "product":
        case "grades":
        case "material_numbers":
        case "legacy_material_numbers":
        case "product_codes":
        case "product_application_codes":
        case "plants":
        case "lines":
        case "unit":
        case "internal_team":
        case "platform":
        case "program":
        case "brand":
        case "nameplate":
        case "country":
        case "region":
        case "production_plant":
        case "city": {
            const arrDate = getString(item, key).split("\n");
            return (
                <>
                    {arrDate.map((elem, index) => (
                        <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={elem}>
                            {elem}
                        </TableRowItemSpan>
                    ))}
                </>
            );
        }

        case "actual_sale_price":
            const arrDate = getString(item, key).split("\n");
            return (
                <>
                    {arrDate.map((elem, index) => {
                        if (elem && !isNaN(+elem)) {
                            elem = +elem >= 10 ? `$${(+elem).toFixed(2)}` : (+elem).toFixed(2)
                        } else {
                            elem = "0.00"
                        }

                        return <TableRowItemSpan  className="text-right" key={`TableRowItemSpan-${index}`} title={elem}>
                            {elem}
                        </TableRowItemSpan>
                    })}
                </>
            );

        case "grade_sub_status":
            return (
                <>
                    {getString(item, key).split("\n").map((elem, index) => {
                        return (
                            <div key={`div-${index}`}>
                                <TableRowItemStatus
                                    key={`TableRowItemGradeSubStatus-${index}`}
                                    status={elem}
                                    list={gradeStatus}
                                />
                            </div>
                        )
                    })}
                </>
            );

        case "sub_status":
            return <TableRowItemStatus status={getString(item, key)} list={[...projectStatus]}/>;

        case "status":
        case "budget":
            return <TableRowItemStatus status={getString(item, key)} list={projectConsistent}/>;

        case "revenue":
            return (
                <TableRowItemSpan className="text-center">
                    {`$${numberWithCommas(getString(item, key))}`}
                </TableRowItemSpan>
            );

        case "volume":
            return (
                <TableRowItemSpan className="text-center">
                    {`${numberWithCommas(getString(item, key))} lb`}
                </TableRowItemSpan>
            );

        case "move_to_current":
        case "end_date":
        case "sop":
        case "eop": {
            const arrDate = getString(item, key).split("\n");
            return (
                <>
                    {arrDate.map((elem, index) => (
                        <TableRowItemSpan key={`TableRowItemSpan-${index}`} title={formatDate(elem)}>
                            {formatDate(elem)}
                        </TableRowItemSpan>
                    ))}
                </>
            );
        }

        case "los_date":
        case "created_at":
        case "updated_at":
        case "sub_status_changed_at": {
            const elem = getString(item, key);
            const formatValue = elem.toString() === '-' ? elem : formatDate(elem);

            return (
                <TableRowItemSpan title={formatValue}>
                    {formatValue}
                </TableRowItemSpan>
            );
        }

        case "gross_margin":
            return (
                <TableRowItemSpan
                    title={getString(item, key)}
                    className={`${getString(item, key) < 0 ? "clr-red" : ""} text-right`}
                >
                    {`${getString(item, key)}%`}
                </TableRowItemSpan>
            );

        default:
            if (key.startsWith("volume")) {
                return (
                    <TableRowItemSpan className="text-center">
                    {`${numberWithCommas(getString(item, key))} lb`}
                    </TableRowItemSpan>
                );
            }
            return <TableRowItemSpan title={getString(item, key)}>{getString(item, key)}</TableRowItemSpan>;
    }
};
