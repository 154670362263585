import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import parse from 'html-react-parser';
import {compose} from "redux";
import {withServiceConsumer} from "Services/Context";
import {withTagDefaultProps} from "Hoc/Template";

const defaultProps = {
    column: "",
};

const propTypes = {
    onSortClick: PropTypes.func.isRequired,
    sort: PropTypes.objectOf(PropTypes.any).isRequired,
    column: PropTypes.string,
    service: PropTypes.shape({
        isSortEnabled: PropTypes.func,
    }).isRequired,
};

const TableHeadTitle = ({children, pref, toolTip, column, sort, service: {isSortEnabled}, onSortClick}) => {
    const _isSortEnabled = isSortEnabled || (() => true);
    const isEnabled = _isSortEnabled(column);

    const linkRef = useRef(null);
    const [tooltipPosition, setTooltipPosition] = useState({
        top: '0px',
        left: '0px'
    })

    const updateTooltipPosition = () => {
        if (!linkRef.current) return;

        const rect = linkRef.current.getBoundingClientRect();

        setTooltipPosition({
            top: `${rect.top + 30}px`,
            left: `${rect.left}px`
        });
    };

    useEffect(() => {
        if (typeof window === 'undefined') return
        updateTooltipPosition();

        const resizeObserver = new ResizeObserver(updateTooltipPosition);
        resizeObserver.observe(linkRef.current);

        const mainContent = document.querySelector('.publications-content.main-content');

        const handleScroll = () => {
            updateTooltipPosition();
        };

        if (mainContent) {
            mainContent.addEventListener('scroll', handleScroll);
        }
        window.addEventListener('resize', handleScroll);

        return () => {
            resizeObserver.disconnect();
            if (mainContent) {
                mainContent.removeEventListener('scroll', handleScroll);
            }
            window.removeEventListener('resize', handleScroll);
        };
    }, []);

    return (
        <>
            <a
                ref={linkRef}
                href="#"
                className={`${pref}__table-head-link main-table__head-link ${isEnabled ? 'main-is-enabled' : 'main-is-disabled'}`}
                onClick={e => {
                    e.preventDefault();
                    if (isEnabled) {
                        onSortClick();
                    }
                }}
            >
                {parse(children)}

                {toolTip && (
                    <span
                        className="tooltip tooltip__table"
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                    >
                    <i className="la la-info-circle"/>
                    <span className="tooltip__text tooltip__text__table"
                          style={{top: tooltipPosition.top, left: tooltipPosition.left}}>{parse(toolTip)}</span>
                </span>
                )}
                {isEnabled && (
                    <span className={`${pref}__table-head-link main-table__head-link-icons`}>
                <i
                    className={`fas fa-sort-up main-table__head-link-icon 
                ${sort.column === column && sort.direction === "asc" ? "icon-active" : ""}`}
                />
                <i
                    className={`fas fa-sort-down main-table__head-link-icon 
                ${sort.column === column && sort.direction === "desc" ? "icon-active" : ""}`}
                />
                </span>
                )}
            </a>
        </>
    )
};

TableHeadTitle.defaultProps = defaultProps;
TableHeadTitle.propTypes = propTypes;

export default compose(
    withServiceConsumer,
    withTagDefaultProps(),
)(TableHeadTitle);
